import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
} from '@chakra-ui/react'
import React, { ReactElement } from 'react'

type Props = {
  size?: string
  firstField?: React.MutableRefObject<undefined>
  onClose: () => void
  isOpen: boolean
  children?: ReactElement<any>
  footer?: ReactElement<any>
}

const SideDrawer = ({
  size = 'md',
  firstField = null,
  onClose,
  isOpen,
  children,
  footer,
}: Props) => {
  return (
    <>
      <Drawer
        initialFocusRef={firstField}
        isOpen={isOpen}
        onClose={onClose}
        placement="right"
        size={size}
      >
        <DrawerOverlay />

        <DrawerContent>
          <DrawerCloseButton />

          <DrawerBody>
            <br />

            {children}
          </DrawerBody>

          <DrawerFooter>{footer}</DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}
export default SideDrawer
