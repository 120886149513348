export const NUM_INDIVID_NFTS_TO_EXPORT = 5
export const BASE_FIELDS_TO_DELETE: Array<string> = [
  '__typename',
  'report_screenshots',
  'spoofing_reports_to_tags',
  'external_source',
  'collection_report_changes',
]
export const PROTOCOLS = ['https://', 'http://']

export const CSV_OPTIONS = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
}

export const WEB2_ORDERED_COLUMNS = [
  'report_status',
  'type',
  'url',
  'match_url',
  'domain',
  'screenshot_url',
  'flagged_url',
  'unsanitized_url',
  'created_at',
  'notes',
  'source',
  'last_reported_at',
  'last_resolved_at',
  'score',
]

export const NFT_FIELDS_TO_DELETE: Array<string> = [
  ...BASE_FIELDS_TO_DELETE,
  'organization',
  'report_type',
  'flagged_pre_collection',
  'collection_slug',
  'collection_reports_to_matches',
  'importance_score',
  'collection_reports_to_matches_aggregate',
  'original_collection',
  'flagged_collection',
  'match_types',
]

export const WEB2_FIELDS_TO_DELETE: Array<string> = [
  ...BASE_FIELDS_TO_DELETE,
  'id',
  'organization_id',
  'organization',
  'original_entity_id',
  'spoofing_entity',
  'website_content',
  'social_media_data',
  'domain_data',
  'spoof_matches',
  'approvals',
  'enforcement_requests',
  'spoof_status',
  'estimated_usd_price',
  'first_domain_data',
  'social_media_post',
]

export const PA_FIELDS_TO_DELETE: Array<string> = [
  '__typename',
  'brand',
  'mobile_app',
  'platform',
  'social_media',
  'last_updated_by_user',
  'full_url',
  'id',
  'is_generic',
  'should_monitor',
]

export const DARK_WEB_FIELDS_TO_DELETE: Array<string> = [
  ...WEB2_FIELDS_TO_DELETE,
  'screenshot_url',
  'flagged_url',
  'uploader',
  'spoof_status',
  'estimated_usd_price',
  'first_domain_data',
  'social_media_post',
]

export const MOBILE_APP_FIELDS_TO_DELETE: Array<string> = [
  ...WEB2_FIELDS_TO_DELETE,
  'screenshot_url',
  'flagged_url',
  'uploader',
  'spoof_status',
  'estimated_usd_price',
  'first_domain_data',
  'social_media_post',
  'spoofing_reports_to_tags',
  'source_query',
]
