const EXCEL_MAX_CELL_SIZE = 32767
const MAX_FIELD_SIZE = EXCEL_MAX_CELL_SIZE - 500

export function prepareTextForCsv(field: string): string {
  // Truncate the input to the maximum (with a buffer for when we escape it)
  // TODO: decide if we want to add an explanation to users here.
  field = field.substring(0, MAX_FIELD_SIZE)

  // If the field contains a double quote, replace each instance with two double quotes.
  if (field.includes('"')) {
    field = field.replace(/"/g, '""')
  }

  // If the field contains a double quote, comma, or newline, wrap the entire field in double quotes.
  if (field.includes('"') || field.includes(',') || field.includes('\n')) {
    field = `"${field}"`
  }

  return field
}

export const reorderRowKeys = (row, orderedKeys) => {
  const orderedRow = {}
  orderedKeys.forEach((key) => {
    if (key in row) {
      orderedRow[key] = row[key]
    }
  })

  Object.keys(row).forEach((key) => {
    if (!orderedKeys.includes(key)) {
      orderedRow[key] = row[key]
    }
  })
  return orderedRow
}

export function deleteFields(
  row: Record<string, any>,
  fieldsToDelete: Array<string>,
): void {
  for (const field of fieldsToDelete) {
    if (field in row) {
      delete row[field]
    }
  }
}

export function getTopMatch(spoofMatches) {
  return spoofMatches.reduce(
    (acc, curr) => {
      return acc.suspicion_score > curr.suspicion_score ? acc : curr
    },
    { suspicion_score: -Infinity },
  )
}

export function getTags(reportTags, isEmployeeView) {
  if (isEmployeeView) {
    return reportTags.map((report_tag) => report_tag?.tag?.name).join(',') || null
  } else {
    return (
      reportTags
        .filter((report_tag) => report_tag?.tag?.is_internal === false)
        .map((report_tag) => report_tag?.tag?.name)
        .join(',') || null
    )
  }
}
