import { Flex, Spacer } from '@chakra-ui/react'
import {
  Protected_Assets_Bool_Exp,
  useGetProtectedAssetPlatformsByOrgQuery,
} from '../../generated/graphql'
import { useOrgID } from '../../hooks/id_token_claims'
import { containsFilterByType, genReportFilter } from '../../hooks/report_table_filters'
import { useSelectedReportFilters } from '../../pages/reports'

import { ReportFilterType } from '../../utils/constants'

import FilterDropdown from '../tables/filter_dropdown'
import { IoCubeSharp } from 'react-icons/io5'
import { IoMdSearch } from 'react-icons/io'
import SearchFilterToken from '../collection_reports_table/search_filter_token'

export enum FilterBarStyle {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export function protectedAssetsWhereClause(
  selectedReportFilters,
  selectedStatuses,
  searchTerm,
  brandId,
  orgId,
): Protected_Assets_Bool_Exp {
  const whereClauses: any[] = [
    {
      platform_name: { _eq: selectedReportFilters[0]?.value },
    },
    ...(brandId
      ? [{ original_entity_id: { _eq: brandId } }]
      : [{ brand: { organization_id: { _eq: orgId } } }]),
    ...(searchTerm.length
      ? [
          {
            _or: [
              {
                full_url: {
                  url: { _ilike: `%${searchTerm}%` },
                },
              },
              {
                mobile_app: {
                  link: { _ilike: `%${searchTerm}%` },
                },
              },
              {
                social_media: {
                  slug: { _ilike: `%${searchTerm}%` },
                },
              },
            ],
          },
        ]
      : []),
  ]

  if (selectedStatuses.includes('primary_only')) {
    whereClauses.push({
      is_primary: { _eq: true },
    })
  }

  if (selectedStatuses.includes('monitoring_only')) {
    whereClauses.push({
      should_monitor: { _eq: true },
    })
  }

  return {
    _and: whereClauses,
  }
}

const ProtectedAssetsTableFilterBar = ({ style = FilterBarStyle.HORIZONTAL }) => {
  const orgID = useOrgID()
  const [selectedReportFilters, setSelectedReportFilters] = useSelectedReportFilters()

  const { data: protectedAssetsAndPlatforms } = useGetProtectedAssetPlatformsByOrgQuery(
    {
      variables: {
        organizationId: orgID,
      },
    },
  )

  return (
    <Flex
      align="left"
      flexDirection={style == FilterBarStyle.VERTICAL ? 'column' : 'row'}
    >
      <FilterDropdown
        dropdownIcon={<IoCubeSharp size="18" />}
        filterOptions={protectedAssetsAndPlatforms?.protected_assets.map(
          (protected_assets) =>
            genReportFilter(ReportFilterType.Platform, protected_assets.platform_name),
        )}
        filterType={ReportFilterType.Platform}
        marginRight="8px"
        w="155px"
      />

      {containsFilterByType(ReportFilterType.Search, selectedReportFilters) && (
        <Flex marginRight="8px">
          <SearchFilterToken
            dropdownIcon={<IoMdSearch size="16" />}
            filterType={ReportFilterType.Search}
            selectedReportFilters={selectedReportFilters}
            setSelectedReportFilters={setSelectedReportFilters}
          />
        </Flex>
      )}

      <Spacer />
    </Flex>
  )
}

export default ProtectedAssetsTableFilterBar
